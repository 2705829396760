:not(:root):fullscreen::backdrop {
  background: white; /* Ensures the Player's Fullscreen mode has a white background */
}

.player-el {
  height: 500px; /* player-el must have a discretely defined height (e.g. px, vh, etc.) */
}

.App {
  --side-padding: 10%;
  --logo-padding: 10px;
  --logo-width: 200px;
}

@media (max-width: 600px) {
  .App {
    --side-padding: 5%;
    --logo-padding: 10px;
    --logo-width: 100px;
  }
}

.App-header {
  align-items: center;
  background: #f5f5f5;
  display: flex;
  padding: 0 var(--side-padding);
}

.App-header a {
  text-decoration: none;
  transition: all 300ms;
}

.App-header a:hover {
  filter: invert(30%);
}

.App-header div:first-child {
  flex: auto;
}

.App-header a:nth-child(n + 2) {
  margin-left: 10px;
  position: relative;
}

.App-logo {
  margin: var(--logo-padding) 0;
  width: var(--logo-width);
}

.App-main {
  padding: 10px var(--side-padding);
}
